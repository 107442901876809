<template>
  <div style="background: #f7f7f9; height: 100vh">
    <!-- 顶部 -->
    <div style="height: 200px">
      <div class="header"></div>
      <div class="info">
        <div class="infoImg">
          <img src="https://lycaljdapi.dexian.ren//upload/images/2021/12/6fc2966a-108.png"
            alt=""
            style="width: 100%" />
        </div>
        <div class="infoName">{{ list.Name }}</div>
        <div class="infoNames">
          {{ list.StaName }}
        </div>
      </div>
    </div>
    <!-- 列表 -->
    <div class="list">
      <div style="padding: 5px 0px">
        <van-cell title="服务管理"
          is-link
          to="/CalYl/service" />
      </div>
    </div>
    <!-- 按钮 -->
    <div style="margin-top: 40px">
      <div class="conter">
        <van-button type="primary"
          block
          @click="wxBind()">绑 定 微 信</van-button>
      </div>
      <div class="conter">
        <van-button type="primary"
          block
          @click="wxRemove()">解 绑 微 信</van-button>
      </div>
      <div class="conter">
        <van-button type="info"
          block
          @click="quit()">退 出 登 录</van-button>
      </div>
    </div>
    <!-- 底部 -->
    <div>
      <van-tabbar v-model="tabbar"
        active-color="#ffc200">
        <van-tabbar-item icon="column"
          to="/CalYl/">订单管理</van-tabbar-item>
        <!-- <van-tabbar-item icon="goods-collect" to="/CalYl/service"
          >服务管理</van-tabbar-item
        > -->
        <!-- <van-tabbar-item icon="bookmark" to="/CalYl/history"
          >套餐服务</van-tabbar-item
        > -->
        <van-tabbar-item icon="manager"
          to="/CalYl/prompt">个人中心</van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
import {
  getToken,
  setToken,
  removeToken,
  getOpenId,
  removeStaLevel,
  removeOCode,
  removeOpenId,
} from "@/utils/auth";
import {
  WeGetStaOrderPage,
  BindWechat,
  UnBindWechat,
  WeGetPersonnel,
} from "@/api/Lycal.js";
import wx from "weixin-js-sdk";
export default {
  data () {
    return {
      ServiceList: [],
      tabbar: 1,
      popupShow: false,
      acctoken: "",
      filters: {
        page: 1,
        limit: 999,
      },
      list: {},
      wxFrom: { WechatName: "", WechatAvatar: "" },
    };
  },
  created () {
    // 获取并保存openid
    if (this.$route.query["accToken"]) {
      setToken(this.$route.query["accToken"]);
    }
    this.acctoken = getToken();
    this.getPersonnel();
  },
  methods: {
    // 跳转
    jumpUrl (row) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + row,
        });
      } else {
        this.$router.push(row);
      }
    },
    // 退出登录
    quit () {
      removeToken();
      removeStaLevel();
      removeOCode();
      removeOpenId();
      window.wx.miniProgram.navigateTo({
        url: "../login/login",
      });
    },
    // 微信绑定
    wxBind () {
      this.wxFrom.accToken = this.acctoken;
      this.wxFrom.OpenID = getOpenId();
      BindWechat(this.wxFrom).then((res) => {
        if (res.data.code == 0) {
          Toast.success("绑定成功!");
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 微信解绑
    wxRemove () {
      var acctoken = this.acctoken;
      UnBindWechat({ accToken: acctoken, OpenID: getOpenId() }).then((res) => {
        if (res.data.code == 0) {
          Toast.success("已解除绑定!");
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 获取用户详情
    getPersonnel () {
      var acctoken = this.acctoken;
      WeGetPersonnel({ accToken: acctoken }).then((res) => {
        this.list = res.data.data;
      });
    },
  },
};
</script>

<style scoped>
.header {
  position: absolute;
  top: -7px;
  width: 100%;
  height: 190px;
  background: url('../../assets/calBG.png') no-repeat center center;
  background-size: 100%;
}
.personal {
  position: absolute;
  width: 100%;
  top: 10px;
  align-items: center;
  text-align: center;
}
.homebanner img {
  width: 100%;
  vertical-align: top;
}
.homebanner {
  font-size: 14px;
  color: #646566;
}
.tost {
  float: left;
  background: #d81e06;
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 7px;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 5px;
}
.packages {
  margin: 10px;
  padding: 7px;
  background: #ffc002;
  border-radius: 5px;
}
.packagesName {
  color: white;
  font-size: 18px;
  font-weight: 600;
  padding: 0 7px 7px 7px;
}
.info {
  z-index: 99;
  position: absolute;
  top: 15px;
  /* left: 30%; */
  width: 100%;
  height: 150px;
  /* background: #646566; */
}
.info .infoImg {
  position: relative;
  left: 30%;
  width: 80px;
  height: 80px;
  border-radius: 50px;
  margin-left: 35px;
  overflow: hidden;
}
.infoName {
  margin-top: 5px;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
}
.infoNames {
  margin: 5px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
}
.conter button {
  width: 80%;
  margin: 0 10% 15px;
  border: none;
  padding: 8px 10px;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  height: 45px;
}
.list {
  width: 90%;
  margin: 15px 5%;
  background: #fff;
  border-radius: 8px;
}
</style>